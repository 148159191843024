<template>
  <div class="page">
    <div class="top"></div>
    <div class="modular record-modular">
      <div class="modular-row">
        <div class="modular-list">
          <div class="modular-list-left">
            <div class="modular-list-title title-flex">
              <span>自动记账</span>
              <img
  src="//download-cos.yofish.com/yofish-gongjushiyebu/20231229103008314-crown-icon.png" alt="" />
            </div>
          </div>
        </div>
        <div class="modular-list" @click="jumpQuickAction">
          <div class="modular-list-left">
            <p class="modular-list-title">快捷指令获取</p>
            <p class="modular-list-desc">点击跳转至系统快捷指令安装</p>
          </div>
          <div class="modular-list-right">
            <img
    src="//download-cos.yofish.com/yofish-gongjushiyebu/20231218102539568-arrow-icon.png" alt="" />
          </div>
        </div>
        <div class="modular-list" @click="jumpIntroAction">
          <div class="modular-list-left">
            <p class="modular-list-title">自动记账教程</p>
          </div>
          <div class="modular-list-right">
            <img
    src="//download-cos.yofish.com/yofish-gongjushiyebu/20231218102539568-arrow-icon.png" alt="" />
          </div>
        </div>
      </div>
    </div>
    <div class="modular">
      <div class="modular-row">
        <div class="modular-list" @click="jumpAtAction">
          <div class="modular-list-left">
            <div class="modular-list-title">
              <span>支持的应用</span>
            </div>
          </div>
          <div class="modular-list-right">
            <img
    src="//download-cos.yofish.com/yofish-gongjushiyebu/20231218102539568-arrow-icon.png" alt="" />
          </div>
        </div>
        <div class="modular-list" @click="jumpMobileSettingAction">
          <div class="modular-list-left">
            <p class="modular-list-title">粘贴权限</p>
            <p class="modular-list-desc">授权有鱼记账读取粘贴板</p>
          </div>
          <div class="modular-list-right">
            <img
    src="//download-cos.yofish.com/yofish-gongjushiyebu/20231218102539568-arrow-icon.png" alt="" />
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="modular">
      <div class="modular-row">
        <div class="modular-list">
          <div class="modular-list-left">
            <p class="modular-list-title">备注自动填写</p>
            <p class="modular-list-desc">关闭后默认备注将设置为空</p>
          </div>
          <div class="modular-list-right">
            <y-switch @input="switchAction" :value="value" active-value="1" inactive-value="0" />
          </div>
        </div>
      </div>
    </div> -->
    <div class="bottom"></div>
  </div>
</template>
<script>
// import YSwitch from '@/components/switch/index';
import { jsGoSetting } from '@/utils/bridge';

export default {
  name: 'MagicHelp',
  // components: { YSwitch },
  data() {
    return {
      value: '1',
    };
  },
  mounted() {
    document.title = '自动记账';
  },
  methods: {
    switchAction(val) {
      this.value = val;
    },
    jumpQuickAction() {
      let url = '';
      if (this.$route.path.includes('lite')) {
        url = 'https://www.icloud.com/shortcuts/bb3c4fa2b2c0412ba58c45f91d936c62';
      } else {
        url = '//www.icloud.com/shortcuts/7f67423ea07e4966b571caf9447c6bdd';
      }
      window.location.href = url;
    },
    jumpAtAction() {
      this.$router.push('/magic/use');
    },
    jumpIntroAction() {
      if (this.$route.path.includes('lite')) {
        this.$router.push('/lite/magic/intro');
        return;
      }
      this.$router.push('/magic/intro');
    },
    jumpMobileSettingAction() {
      jsGoSetting();
    },
  },
};
</script>
<style lang="scss" scoped>
  .page {
    width: 750px;
    min-height: 100%;
    margin: auto;
    background: #F6F6F6;
    font-family: PingFangSC, PingFang SC;
    .top {
      padding-top: 20px;
    }
    .bottom {
      padding-bottom: 20px;
    }
    .modular {
      border-radius: 12px;
      background-color: #ffffff;
      margin-bottom: 40px;
      margin-left: 26px;
      margin-right: 26px;
      &-row {
        margin: 0 20px;
      }
      &-list {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 32px 0;
        border-bottom: 2px solid #EAE7E7;
        &:last-child {
          border: none;
        }
        &-title {
          font-size: 28px;
          font-weight: 500;
          color: #333333;
          line-height: 40px;
        }
        &-desc {
          font-size: 20px;
          font-weight: 400;
          color: #999999;
          line-height: 28px;
          margin-top: 8px;
        }
        &-right {
          img {
            width: 32px;
            height: 32px;
            transform: rotate(90deg);
          }
        }
      }
    }
    .title-flex {
      display: flex;
      align-items: center;
      img {
        width: 32px;
        height: 32px;
        margin-left: 8px;
      }
    }
  }
</style>
